import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

const getAuthToken = () => sessionStorage.getItem('authToken');
const removeAuthToken = () => sessionStorage.removeItem('authToken');

const decodeToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (err) {
    console.error('Erro ao decodificar o token:', err);
    return null;
  }
};

const isTokenValid = (token) => {
  if (!token) return false;
  const decoded = decodeToken(token);
  const currentTime = Date.now() / 1000;
  return decoded?.exp > currentTime;
};

const initialState = {
  isLogin: false,
  currentUser: {
    id: null,
    role: null,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      const { token } = action.payload;
      if (token && isTokenValid(token)) {
        const decoded = decodeToken(token);
        state.isLogin = true;
        state.currentUser = {
          id: decoded.sub,
          role: decoded.role,
        };
        sessionStorage.setItem('authToken', token);
      }
    },
    initializeAuth(state) {
      const token = getAuthToken();
      if (token && isTokenValid(token)) {
        const decoded = decodeToken(token);
        state.isLogin = true;
        state.currentUser = {
          id: decoded.sub,
          role: decoded.role,
        };
      } else {
        state.isLogin = false;
        state.currentUser = {
          id: null,
          role: null,
        };
        removeAuthToken();
      }
    },
    logout(state) {
      state.isLogin = false;
      state.currentUser = {
        id: null,
        role: null,
      };
      removeAuthToken();
    },
    updateCurrentUser(state, action) {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload,
      };
    },
  },
});

export const { setCurrentUser, initializeAuth, logout, updateCurrentUser } = authSlice.actions;

export default authSlice.reducer;
