const ptMessages = {
  'menu.dashboard': 'Dashboard',
  'menu.users': 'Usuários',
  'menu.clients': 'Clientes',
  'menu.appointments': 'Agendamentos',
  'menu.registerappointment': 'Cadastrar Agendamento',
  'menu.listappointment': 'Listar Agendamentos',
  'menu.jobtitle': 'Cargos',
  'menu.typeofservice': 'Tipos de Serviço',

  'menu.dashboards': 'pt|Dashboards',
  'menu.default': 'pt|Default',
  'menu.visual': 'pt|Visual',
  'menu.analytic': 'pt|Analytic',
  'menu.apps': 'pt|Apps',
  'menu.calendar': 'pt|Calendar',
  'menu.chat': 'pt|Chat',
  'menu.contacts': 'pt|Contacts',
  'menu.mailbox': 'pt|Mailbox',
  'menu.tasks': 'pt|Tasks',
  'menu.pages': 'pt|Pages',
  'menu.authentication': 'pt|Authentication',
  'menu.login': 'pt|Login',
  'menu.register': 'pt|Register',
  'menu.forgot-password': 'pt|Forgot Password',
  'menu.reset-password': 'pt|Reset Password',
  'menu.blog': 'pt|Blog',
  'menu.home': 'pt|Home',
  'menu.grid': 'pt|Grid',
  'menu.list': 'pt|List',
  'menu.detail': 'pt|Detail',
  'menu.miscellaneous': 'pt|Miscellaneous',
  'menu.faq': 'pt|Faq',
  'menu.knowledge-base': 'pt|Knowledge Base',
  'menu.error': 'pt|Error',
  'menu.coming-soon': 'pt|Coming Soon',
  'menu.pricing': 'pt|Pricing',
  'menu.search': 'pt|Search',
  'menu.mailing': 'pt|Mailing',
  'menu.empty': 'pt|Empty',
  'menu.portfolio': 'pt|Portfolio',
  'menu.profile': 'pt|Profile',
  'menu.standard': 'pt|Standard',
  'menu.settings': 'pt|Settings',
  'menu.blocks': 'pt|Blocks',
  'menu.images': 'pt|Images',
  'menu.tabular-data': 'pt|Tabular Data',
  'menu.thumbnails': 'pt|Thumbnails',
  'menu.cta': 'pt|Cta',
  'menu.gallery': 'pt|Gallery',
  'menu.stats': 'pt|Stats',
  'menu.steps': 'pt|Steps',
  'menu.details': 'pt|Details',
  'menu.interface': 'pt|Interface',
  'menu.components': 'pt|Components',
  'menu.accordion': 'pt|Accordion',
  'menu.alerts': 'pt|Alerts',
  'menu.badge': 'pt|Badge',
  'menu.breadcrumb': 'pt|Breadcrumb',
  'menu.buttons': 'pt|Buttons',
  'menu.button-group': 'pt|Button Group',
  'menu.card': 'pt|Card',
  'menu.close-button': 'pt|Close Button',
  'menu.collapse': 'pt|Collapse',
  'menu.dropdowns': 'pt|Dropdowns',
  'menu.list-group': 'pt|List Group',
  'menu.modal': 'pt|Modal',
  'menu.navs': 'pt|Navs',
  'menu.offcanvas': 'pt|Offcanvas',
  'menu.pagination': 'pt|Pagination',
  'menu.popovers': 'pt|Popovers',
  'menu.progress': 'pt|Progress',
  'menu.spinners': 'pt|Spinners',
  'menu.toasts': 'pt|Toasts',
  'menu.tooltips': 'pt|Tooltips',
  'menu.forms': 'pt|Forms',
  'menu.layouts': 'pt|Layouts',
  'menu.validation': 'pt|Validation',
  'menu.wizard': 'pt|Wizard',
  'menu.input-group': 'pt|Input Group',
  'menu.input-mask': 'pt|Input Mask',
  'menu.generic-forms': 'pt|Generic Forms',
  'menu.controls': 'pt|Controls',
  'menu.autocomplete': 'pt|Autocomplete',
  'menu.checkbox-radio': 'pt|Checkbox-Radio',
  'menu.date-picker': 'pt|Date Picker',
  'menu.dropzone': 'pt|Dropzone',
  'menu.editor': 'pt|Editor',
  'menu.input-spinner': 'pt|Input Spinner',
  'menu.rating': 'pt|Rating',
  'menu.select': 'pt|Select',
  'menu.slider': 'pt|Slider',
  'menu.tags': 'pt|Tags',
  'menu.time-picker': 'pt|Time Picker',
  'menu.plugins': 'pt|Plugins',
  'menu.carousel': 'pt|Carousel',
  'menu.charts': 'pt|Charts',
  'menu.clamp': 'pt|Clamp',
  'menu.context-menu': 'pt|Context Menu',
  'menu.datatables': 'pt|Datatables',
  'menu.editable-rows': 'pt|Editable Rows',
  'menu.editable-boxed': 'pt|Editable Boxed',
  'menu.server-side': 'pt|Server Side',
  'menu.boxed-variations': 'pt|Boxed Variations',
  'menu.lightbox': 'pt|Lightbox',
  'menu.maps': 'pt|Maps',
  'menu.notification': 'pt|Notification',
  'menu.players': 'pt|Players',
  'menu.scrollbar': 'pt|Scrollbar',
  'menu.shortcuts': 'pt|Shortcuts',
  'menu.sortable': 'pt|Sortable',
  'menu.content': 'pt|Content',
  'menu.icons': 'pt|Icons',
  'menu.cs-line-icons': 'pt|CS Line Icons',
  'menu.cs-interface-icons': 'pt|CS Interface Icons',
  'menu.bootstrap-icons': 'pt|Bootstrap Icons',
  'menu.tables': 'pt|Tables',
  'menu.typography': 'pt|Typography',
  'menu.menu': 'pt|Menu',
  'menu.horizontal': 'pt|Horizontal',
  'menu.vertical': 'pt|Vertical',
  'menu.vertical-hidden': 'pt|Vertical Hidden',
  'menu.vertical-no-hidden': 'pt|Vertical No Hidden',
  'menu.mobile-only': 'pt|Mobile Only',
  'menu.sidebar': 'pt|Sidebar',
  'menu.Docs': 'pt|Docs',
  'menu.connections': 'pt|Connections',
  'menu.bookmarks': 'pt|Bookmarks',
  'menu.requests': 'pt|Requests',
  'menu.account': 'pt|Account',
  'menu.password': 'pt|Password',
  'menu.devices': 'pt|Devices',
  'menu.notifications': 'pt|Notifications',
  'menu.email': 'pt|Email',
  'menu.sms': 'pt|SMS',
  'menu.downloads': 'pt|Downloads',
  'menu.documents': 'pt|Documents',
  'menu.videos': 'pt|Videos',
  'menu.products': 'pt|Products',
  'menu.orders': 'pt|Orders',
  'menu.customers': 'pt|Customers',
  'menu.storefront': 'pt|Storefront',
  'menu.filters': 'pt|Filters',
  'menu.categories': 'pt|Categories',
  'menu.cart': 'pt|Cart',
  'menu.checkout': 'pt|Checkout',
  'menu.invoice': 'pt|Invoice',
  'menu.shipping': 'pt|Shipping',
  'menu.discount': 'pt|Discount',
  'menu.general': 'pt|General',

  'menu.upgrade': 'pt|Upgrade',
  'menu.community': 'pt|Community',
  'menu.community-list': 'pt|Community List',
  'menu.getting-started': 'pt|Getting Started',
  'menu.analysis': 'pt|Analysis',
  'menu.services': 'pt|Services',
  'menu.database': 'pt|Database',
  'menu.database-add': 'pt|New Database',
  'menu.database-detail': 'pt|Database Detail',
  'menu.storage': 'pt|Storage',
  'menu.hosting': 'pt|Hosting',
  'menu.billing': 'pt|Billing',
  'menu.security': 'pt|Security',
  'menu.support': 'pt|Support',
  'menu.docs': 'pt|Docs',
  'menu.docsdetail': 'pt|Docs Detail',
  'menu.tickets': 'pt|Tickets',
  'menu.tickets-detail': 'pt|Teicket Detail',

  'menu.elearning': 'pt|Elearning',
  'menu.school': 'pt|School',
  'menu.courses': 'pt|Courses',
  'menu.explore': 'pt|Explore',
  'menu.quiz': 'pt|Quiz',
  'menu.result': 'pt|Result',
  'menu.paths': 'pt|Paths',
  'menu.instructor': 'pt|Instructor',
  'menu.misc': 'pt|Miscellaneous',
  'menu.player': 'pt|Player',
  'menu.material': 'pt|Material',
  'menu.syllabus': 'pt|Syllabus',
};
export default ptMessages;
