/* eslint-disable */
import { lazy } from 'react';

const dashboard = {
  index: lazy(() => import('views/dashboard/Dashboard'))
};

const user = {
  index: lazy(() => import('views/user/Users'))
};

const appointment = {
  index: lazy(() => import('views/agenda/Tasks')),
};

const jobTitles = {
  index: lazy(() => import('views/job-title/EditableRows/EditableRows'))
};

const typeOfService = {
  index: lazy(() => import('views/type-of-service/EditableRows/EditableRows'))
};

const client = {
  index: lazy(() => import('views/client/Clients'))
};

const appRoot = '/'.endsWith('/') ? '/'.slice(1, '/'.length) : '/';

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: '/',
      exact: true,
      redirect: true,
      to: `${appRoot}/login`,
    },
    {
      path: `${appRoot}/dashboard`,
      component: dashboard.index,
      label: 'menu.dashboard',
      icon: 'clipboard',
    },
    {
      path: `${appRoot}/appointments`,
      label: 'menu.appointments',
      icon: 'calendar',
      component: appointment.index,
    },
    {
      path: `${appRoot}/users`,
      component: user.index,
      label: 'menu.users',
      icon: 'user',
    },
    {
      path: `${appRoot}/clients`,
      component: client.index,
      label: 'menu.clients',
      icon: 'building',
    },
    {
      path: `${appRoot}/job-titles`,
      component: jobTitles.index,
      label: 'menu.jobtitle',
      icon: 'crown',
    },
    {
      path: `${appRoot}/type-of-services`,
      component: typeOfService.index,
      label: 'menu.typeofservice',
      icon: 'tool',
    },
  ],
  sidebarItems: [
    { path: '#connections', label: 'menu.connections', icon: 'diagram-1', hideInRoute: true },
    { path: '#bookmarks', label: 'menu.bookmarks', icon: 'bookmark', hideInRoute: true },
    { path: '#requests', label: 'menu.requests', icon: 'diagram-2', hideInRoute: true },
    {
      path: '#account',
      label: 'menu.account',
      icon: 'user',
      hideInRoute: true,
      subs: [
        { path: '/settings', label: 'menu.settings', icon: 'gear', hideInRoute: true },
        { path: '/password', label: 'menu.password', icon: 'lock-off', hideInRoute: true },
        { path: '/devices', label: 'menu.devices', icon: 'mobile', hideInRoute: true },
      ],
    },
    {
      path: '#notifications',
      label: 'menu.notifications',
      icon: 'notification',
      hideInRoute: true,
      subs: [
        { path: '/email', label: 'menu.email', icon: 'email', hideInRoute: true },
        { path: '/sms', label: 'menu.sms', icon: 'message', hideInRoute: true },
      ],
    },
    {
      path: '#downloads',
      label: 'menu.downloads',
      icon: 'download',
      hideInRoute: true,
      subs: [
        { path: '/documents', label: 'menu.documents', icon: 'file-text', hideInRoute: true },
        { path: '/images', label: 'menu.images', icon: 'file-image', hideInRoute: true },
        { path: '/videos', label: 'menu.videos', icon: 'file-video', hideInRoute: true },
      ],
    },
  ],
};

export default routesAndMenuItems;
