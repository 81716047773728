import React, { memo, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import RouteItem from './RouteItem';

const RouteIdentifier = ({ routes, fallback = <div className="loading" />, notFoundPath = '/page-not-found' }) => (
  <Suspense fallback={fallback}>
    <Switch>
      {routes.map((route, rIndex) => (
        <RouteItem key={`r.${rIndex}`} {...route} />
      ))}
      <Redirect to={notFoundPath} />
    </Switch>
  </Suspense>
);

export default memo(RouteIdentifier);
