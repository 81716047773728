import { lazy } from 'react';

const NotFound = lazy(() => import('views/error/NotFound'));
const Login = lazy(() => import('views/authentication/Login'));
const ForgotPassword = lazy(() => import('views/authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('views/authentication/ResetPassword'));
const RegisterPassword = lazy(() => import('views/authentication/RegisterPassword'));
const Unauthorized = lazy(() => import('views/error/Unauthorized'));
const InvalidAccess = lazy(() => import('views/error/InvalidAccess'));
const App = lazy(() => import('App.js'));

const defaultRoutes = [
  { path: '/page-not-found', exact: true, component: NotFound },
  { path: '/login', exact: true, component: Login },
  { path: '/forgot-password', exact: true, component: ForgotPassword },
  { path: '/reset-password/:verificationToken', exact: true, component: ResetPassword },
  { path: '/register-password/:verificationToken', exact: true, component: RegisterPassword },
  { path: '/unauthorized', exact: true, component: Unauthorized },
  { path: '/invalid-access', exact: true, component: InvalidAccess },
  { path: '/', component: App },
];

export default defaultRoutes;
